import { useEffect, useState } from "react";
import Coord from "../logic/math/Coord";

export const useContainerDimensions = (ref: React.RefObject<any>) => {
	const [dimensions, setDimensions] = useState(Coord.Zero);

	useEffect(() => {
		const getDimensions = () => new Coord(
			(ref && ref.current.offsetWidth) || 0,
			(ref && ref.current.offsetHeight) || 0
		);

		const onResize = () => { setDimensions(getDimensions()) }

		if(ref.current) {
			setDimensions(getDimensions());
		}

		window.addEventListener('resize', onResize);
		return () => { window.removeEventListener('resize', onResize) }
	}, [ref]);

	return dimensions;
}