import { ReactNode } from "react";
import { tw } from "../logic/utility";

export const SideMenu = (props: {
	open?: boolean,
	content?: {
		id?: string,
		tooltip?: string,
		icon: ReactNode,
		fullText: ReactNode,
		onClick?: () => void
	}[],
	bottomContent?: ReactNode }) => {

	const styles = {
		container: tw(
			props.open ? "w-96" : "w-24", "h-full",
			"fixed z-50",
			"overflow-x-hidden",
			"flex-none flex flex-col justify-between",
			"backdrop-blur-sm",
			"border-r-2 border-gray-200 dark:border-gray-800 border-opacity-50 dark:border-opacity-50",
			"transition-all ease-out"),

		blur: tw(
			"absolute inset-0 -z-10",
			"blur-3xl",
			"bg-gradient-to-t from-sky-200 to-stone-200 opacity-50",
			"dark:bg-grdient-to-t dark:from-blue-900 dark:to-stone-800 dark:opacity-50"),

		content: tw("w-full shrink"),

		button: {
			wrapper: tw("w-full h-24 flex items-center group"),
			icon: {
				container: tw(
					"w-24 h-full",
					"flex-none",
					"flex justify-center items-center"),

				img: tw(
					"w-4/5 h-4/5",
					"flex justify-center items-center",
					"opacity-50 group-hover:opacity-100 group-hover:scale-110",
					"transition-all duration-100"),
			},
			text: tw(
				"w-full overflow-hidden",
				"text-left whitespace-nowrap",
				"text-lg",
				props.open ? "animate-slideLTR" : "", "origin-left ease-in"),
		},

		bottomContainer: tw(
			"h-24 flex-none",
			"flex justify-center items-center")
	};

	return ( <>
		<nav className={styles.container}>

			<div className={styles.blur}></div> {/* Background blur container */}

			<div className={styles.content}>

				{props.content?.map(item => <>
					<button
						id={`sidemenu-${item.id}-button`}
						onClick={item.onClick}
						title={item.tooltip ? item.tooltip : item.fullText?.toString()}
						className={styles.button.wrapper}>

						<div className={styles.button.icon.container}>
							<div className={styles.button.icon.img}>{item.icon}</div>
						</div>

						<div className={styles.button.text}>{item.fullText}</div>
						
					</button>
				</> )}

			</div>

 			<div className={styles.bottomContainer}>{props.bottomContent}</div>
		</nav>
	</> );
}