import { ReactNode } from "react";

export const ButtonBar = (props?: { children?: ReactNode }) => {
	return ( <>
		<div className="
			w-full h-16
			overflow-x-auto overflow-y-hidden
			p-4 space-x-4
			flex items-center justify-between">
			{props?.children}
		</div>
	</> );
}