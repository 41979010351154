import ReactDOM from 'react-dom/client'
import "./styles/tailwind.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import { Home } from './pages/Home';
import { Editor } from './pages/Editor';

ReactDOM.createRoot(document.getElementById('root')!).render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />}>
				<Route index element={<Home />} />
				<Route path="/editor" element={<Editor />} />
			</Route>
		</Routes>
	</BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
