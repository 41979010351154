import { ReactNode, useRef, useState } from "react";
import { useDraggable } from "../hooks/useDraggable";
import { clamp } from "../logic/math/funcs";
import { tw } from "../logic/utility";

export const SidePanel = (props?: { children?: ReactNode, maxWidth?: number }) => {
	const minWidth = 16;

	const [width, setWidth] = useState(384);
	const startWidth = useRef(384);

	const [grabRef, dragProps] = useDraggable({
		callbacks: {
			startDrag: _ => { startWidth.current = width },
			whileDrag: (start, current) => { setWidth(clamp(startWidth.current - (current.x - start.x), minWidth, props?.maxWidth)) }
		}
	});

	const styles = {
		container: tw(
			"h-screen",
			"flex z-10",
			"bg-white dark:bg-neutral-900",
			"shadow-[-10px_0_5px_rgba(0,0,0,0.05)]",
			dragProps.dragging ? "select-none" : "select-auto",
			"transition-colors"),
		grabEdge: {
			container: tw(
				"w-4 h-full",
				"cursor-ew-resize",
				"group"),
			highlight: tw(
				width > minWidth && width < (props?.maxWidth || Infinity) ? "w-1/2 group-hover:w-full" : "w-full",
				"h-full",
				"bg-gray-200 dark:bg-neutral-800",
				"flex flex-col items-center justify-center space-y-2",
				"transition-all"),
			circle: tw(
				"rounded-full",
				"bg-gray-400 dark:bg-neutral-700",
				"w-1.5 h-1.5 scale-0",
				width > minWidth && width < (props?.maxWidth || Infinity) ? "group-hover:scale-100" : "scale-100",
				"transition-all")
		},
		contents: tw("flex-1 w-full overflow-y-auto")
	}

	return ( <>
		<div style={{width: width}} className={styles.container}>

			<div ref={grabRef} className={styles.grabEdge.container}> {/* Grab Edge */}
				<div className={styles.grabEdge.highlight}>
					{[...Array(3)].map(_ => <div className={styles.grabEdge.circle}></div>)} {/* Lil grabby circles */}
				</div>
			</div>

			<div className={styles.contents}>
				{props?.children}
			</div>
		</div>
	</> );
}