import { Outlet } from "react-router-dom";

export const App = () => {
	return ( <>
		<main className="
			w-full h-full
			text-black dark:text-neutral-300
			transition-all duration-150">
			<Outlet />
		</main>
	</> );
}