import { useState } from "react";
import { tw } from "../logic/utility";

export const InputField = (props?: {
	placeholder?: string,
	buttonText?: string,
	buttonTitle?: string,
	buttonAction?: (inputValue: string) => void}) => {

	const [inputValue, setInputValue] = useState("");

	const styles = {
		container: tw(
			"w-full h-12",
			"min-w-[12rem]",
			"rounded-full",
			"flex"),

		inputBox: tw(
			"w-full h-full",
			"p-4",
			props?.buttonText ? "rounded-l-full border-r-0" : "rounded-full",
			"bg-white dark:bg-neutral-800",
			"border-2 border-gray-400 dark:border-neutral-700",
			"transition-all"),
			
		button: tw(
			"px-4",
			"font-semibold text-white",
			"border-2 border-gray-400 dark:border-neutral-700",
			"bg-sky-800 dark:bg-sky-900",
			"hover:bg-sky-600 dark:hover:bg-sky-700",
			"transition-all",
			"rounded-r-full")
	};

	return ( <>
		<div className={styles.container}>

			<input placeholder={props?.placeholder}
				onChange={e => { setInputValue(e.target.value) }}
				className={styles.inputBox} />

			{props?.buttonText && /* Only render button if buttonText is provided */
				<button title={props.buttonTitle}
					onClick={() => { props?.buttonAction?.(inputValue) }}
					className={styles.button}>{props.buttonText}</button>
			}

		</div>
	</> );
}