// https://stackoverflow.com/questions/36015691/obtaining-the-return-type-of-a-function
// https://stackoverflow.com/questions/51851677/how-to-get-argument-types-from-function-in-typescript

type IOType = string | number | boolean | IOType[] | { [key: string]: IOType }

export const NodeIO = (props: { modelObject: IOType }) => {

	const entries = Object.entries(props.modelObject);

	return ( <>

		<details className="[&>*:not(summary)]:ml-4">
			<summary>Title</summary>
			<p>Cillum ea adipisicing in labore minim qui ex deserunt nisi enim ipsum.</p>
			<p>Item2</p>
			<details className="[&>*:not(summary)]:ml-4">
				<summary>Subtitle</summary>
				<p>Item3</p>
				<p>Item4</p>
			</details>
		</details>

		{/* {entries.map(entry => {
			switch (typeof entry[1]) {
				case "number":
					var returnNode = <p className="text-sky-400">{JSON.stringify(entry[1])}</p>
					break;
					
				case "string":
					var returnNode = <p className="text-green-500">{JSON.stringify(entry[1])}</p>
					break;

				case "boolean":
					var returnNode = <p className="text-indigo-400">{JSON.stringify(entry[1])}</p>
					break;
					
				default:
					var returnNode = <p className="text-red-300">{JSON.stringify(entry[1])}</p>
					break;
			}

			return returnNode;
		})} */}
	</> );
}