import React, { useEffect, useRef, useState } from "react";
import Coord from "../logic/math/Coord";

export const useDraggable = (props?: { options?: { rightClick?: boolean }, callbacks?: {
	startDrag?: (pos: Coord) => void,
	whileDrag?: (startPos: Coord, pos: Coord) => void,
	endDrag?: (startPos: Coord, pos: Coord) => void } }) => {
	
	const grabRef = useRef<HTMLDivElement>(null);
		
	const [dragging, setDragging] = useState(false);
	const [startPos, setStartPos] = useState<Coord>();
	const [currentPos, setCurrentPos] = useState<Coord>();

	const preventContextMenu = (e: MouseEvent | React.MouseEvent) => { e.preventDefault(); }
	
	const onStartDrag = (e: MouseEvent | React.MouseEvent) => {
		if(props?.options?.rightClick && !(e.button === 2 || (e.button === 0 && e.altKey))) return;
		if(!props?.options?.rightClick && e.button !== 0) return;

		window.addEventListener("contextmenu", preventContextMenu);
		
		setDragging(true);
		
		setStartPos(new Coord(e.clientX, e.clientY));
		setCurrentPos(new Coord(e.clientX, e.clientY));
		
		document.addEventListener('mousemove', onDrag);
		document.addEventListener('mouseup', onEndDrag);
	}
	
	const onDrag = (e: MouseEvent | React.MouseEvent) => {
		setCurrentPos(new Coord(e.clientX, e.clientY));
	}
	
	const onEndDrag = (e: MouseEvent | React.MouseEvent) => {
		setDragging(false);
		document.removeEventListener('mousemove', onDrag);
		document.removeEventListener('mouseup', onEndDrag);
		
		setCurrentPos(new Coord(e.clientX, e.clientY));
		
		setTimeout(() => {
			window.removeEventListener("contextmenu", preventContextMenu);
		}, 10);
	}

	useEffect(() => {
		grabRef?.current?.addEventListener('mousedown', e => { onStartDrag(e); });
	}, [grabRef]);
	
	useEffect(() => {
		if(startPos) {
			props?.callbacks?.startDrag?.(startPos);
		}
	}, [startPos]);
	
	useEffect(() => {
		if(startPos && currentPos) {
			props?.callbacks?.whileDrag?.(startPos, currentPos);
		}
	}, [currentPos]);
	
	useEffect(() => {
		if(startPos && currentPos && !dragging) {
			props?.callbacks?.endDrag?.(startPos, currentPos);
		}
	}, [dragging])

	return [grabRef, { dragging: dragging, start: startPos, pos: currentPos }] as const;
}