import { Bars3BottomLeftIcon, PlusCircleIcon, FolderOpenIcon, ArrowTopRightOnSquareIcon, SunIcon, MoonIcon } from "@heroicons/react/20/solid";
import { Button } from "../components/Button";
import { SideMenu } from "../components/SideMenu";
import { useDarkMode } from "../hooks/useDarkMode";
import { useRef, useState } from "react";
import { SidePanel } from "../components/SidePanel";
import { NodePanel } from "../components/NodePanel";
import { useContainerDimensions } from "../hooks/useContainerDimensions";
import { NodeComponent } from "../components/NodeComponent";
import { ColorUtils, tw } from "../logic/utility";

export const Editor = () => {
	const [darkMode, setDarkMode] = useDarkMode();
	const [menuOpen, setMenuOpen] = useState(false);

	// useReducer instead of useStates?

	const panelMenuContainer = useRef(null);
	const allowedMenuSize = useContainerDimensions(panelMenuContainer);

	const styles = {
		sideMenu: {
			icon: tw("w-1/2 h-1/2"),
			bottomContent: {
				container: tw(
					"whitespace-nowrap",
					"flex items-center justify-center",
					menuOpen ? "-ml-4 space-x-2" : "", "transition-all duration-300 ease-out"),
				icon: {
					sun: tw("w-8 h-8 text-amber-200"),
					moon: tw("w-8 h-8 text-black")
				},
				label: tw(
					menuOpen ? "w-24 animate-slideLTR" : "w-0",
					"overflow-hidden",
					"transition-all duration-200 ease-out")
			}
		},
		panelMenu: {
			container: tw(
				"w-full h-full",
				"inset-0",
				"bg-white dark:bg-neutral-900 box-grid",
				"transition-all",
				"flex justify-between"),
			nodePanelContainer: tw(
				"overflow-hidden",
				"flex-1 w-full h-full",
				menuOpen ? "pl-96" : "pl-24",
				"transition-all ease-out")
		}
	}

	return ( <>
		<SideMenu
			open={menuOpen}
			content={[
				{
					id: "open-menu",
					tooltip: `${menuOpen ? "Close" : "Open"} Menu`,
					icon: <Bars3BottomLeftIcon className={styles.sideMenu.icon} />,
					fullText: <a href="/" title="&Sigma;-node" className="text-4xl font-serif tracking-tighter"><span className="text-blue-600">&Sigma;</span><span>&ndash;node</span></a>,
					onClick: () => { setMenuOpen(!menuOpen) }
				},
				{
					id: "new-graph",
					icon: <PlusCircleIcon className={styles.sideMenu.icon} />,
					fullText: "New NodeGraph",
					onClick: () => { console.log("yeet"); }
				},
				{
					id: "my-graphs",
					icon: <FolderOpenIcon className={styles.sideMenu.icon} />,
					fullText: "My NodeGraphs",
					onClick: () => { console.log("yeet"); }
				},
				{
					id: "save",
					icon: <ArrowTopRightOnSquareIcon className={styles.sideMenu.icon} />,
					fullText: "Save & Export",
					onClick: () => { console.log("yeet"); }
				},
			]}
			bottomContent={ <>
				<div className="w-full flex h-24 p-4">
					<Button title={darkMode ? "Light Mode" : "Dark Mode"} onClick={ () => { setDarkMode(!darkMode); } }>
						<span className={styles.sideMenu.bottomContent.container}>
							{darkMode
								? <SunIcon className={styles.sideMenu.bottomContent.icon.sun} />
								: <MoonIcon className={styles.sideMenu.bottomContent.icon.moon} />}
							<span className={styles.sideMenu.bottomContent.label}>
								{darkMode ? " Light Mode" : "Dark Mode"}
							</span>
						</span>
					</Button>
				</div>
			</> }
		/>

		<div ref={panelMenuContainer}
			className={styles.panelMenu.container}>

			<div className={styles.panelMenu.nodePanelContainer}> {/* Main Editor panel */}
				<NodePanel>
					{[...Array(10)].map(_ =>
						<NodeComponent resizable collapsible color={{shade: ColorUtils.shades[Math.floor(Math.random() * ColorUtils.shades.length)], hue: ColorUtils.colors[Math.floor(Math.random() * ColorUtils.colors.length)]}} />
					)}
				</NodePanel>
			</div>

			<SidePanel maxWidth={allowedMenuSize.x - 96}>
				{[...Array(50)].map((_, i) => <p>{i}</p>)}
			</SidePanel>
		</div>
	</> );
}