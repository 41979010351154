export default class Coord {
	x: number = 0;
	y: number = 0;

	static undefined = null;
	static Zero = new Coord(0, 0);
	static One = new Coord(1, 1);
	static Up = new Coord(0, -1);
	static Down = new Coord(0, 1);
	static Left = new Coord(-1, 0);
	static Right = new Coord(1, 0);

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	plus = (other: Coord) => { return new Coord(this.x + other.x, this.y + other.y) }
	minus = (other: Coord) => { return new Coord(this.x - other.x, this.y - other.y) }
	scale = (factor: number) => { return new Coord(this.x * factor, this.y * factor) }
	negate = () => { return new Coord(-this.x, -this.y) }

	toString = (precision = 4) => { return `(${this.x.toFixed(precision)}, ${this.y.toFixed(precision)})` }
}