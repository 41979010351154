import { ReactElement, ReactNode } from "react";

export const Button = (props: { href?: string, onClick?: () => void, children?: ReactNode, id?: string, title?: string }) => {

	const ButtonType = `${props.href ? "a" : "button"}` as keyof JSX.IntrinsicElements;

	return ( <>
		<ButtonType
			title={props.title}
			className="
				min-w-max
				p-2
				flex-1
				border-2
				rounded-2xl
				border-black border-opacity-25
				dark:border-white dark:border-opacity-25
				bg-sky-900 dark:bg-sky-200 bg-opacity-0 dark:bg-opacity-0
				hover:bg-opacity-10 dark:hover:bg-opacity-10
				hover:shadow-inner dark:hover:shadow-inner
				transition-all"
			onClick={props.onClick}>
			{props.children}
		</ButtonType>
	</> );
}