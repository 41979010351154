import { ReactNode, useRef, useState } from "react";
import { useDraggable } from "../hooks/useDraggable";
import { TransformProvider } from "../hooks/useTransformContext";
import { ButtonBar } from "./ButtonBar";
import { InputField } from "./InputField";
import { Button } from "./Button";
import { MagnifyingGlassIcon, MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from "@heroicons/react/20/solid";
import Coord from "../logic/math/Coord";
import { tw } from "../logic/utility";

export const NodePanel = (props: { children?: ReactNode }) => {

	const startOffset = useRef(Coord.Zero);
	const [offset, setOffset] = useState(Coord.Zero);

	const [grabRef, dragProps] = useDraggable({
		options: { rightClick: true },
		callbacks: {
			startDrag: _ => { startOffset.current = offset },
			whileDrag: (startPos, pos) => { setOffset(startOffset.current.plus(pos.minus(startPos).negate().scale(1/zoomLevel))); }
		}
	});

	const [minZoom, maxZoom] = [0.5, 1.5];
	const [zoomLevel, setZoomLevel] = useState(1);
	
	const applyZoom = (e: WheelEvent | React.WheelEvent) => {
		const delta = -Math.sign(e.deltaY) * 0.1;
		if(zoomLevel + delta < minZoom - 0.01 || zoomLevel + delta > maxZoom + 0.01) return;

		setZoomLevel(z => z + delta);
	}

	const styles = {
		container: tw(
			"relative",
			"w-full h-full",
			dragProps.dragging ? "select-none" : ""),
		bar: {
			container: tw("absolute w-full p-4 z-50"),
			item: tw("whitespace-nowrap")
		},
		panelContainer: tw("relative w-full h-full flex items-center justify-center")
	}

	return ( <>
		<div className={styles.container}>

			<div className={styles.bar.container}> {/* Top bar */}
				<ButtonBar>
					<InputField buttonText="Find" placeholder="Node Name" />
					<Button>🔢</Button>
					<Button>🔢</Button>
					<Button>🔢</Button>
					<Button>🔢</Button>
					<Button>🔢</Button>
					<Button>🔢</Button>
				</ButtonBar>
			</div>

			<div className={styles.bar.container + " bottom-0"}> {/* Bottom bar */}

				<ButtonBar>
					<span className={styles.bar.item}>{offset.toString(0)}</span> {/* Pan offset */}

					<span className={styles.bar.item}> {/* Zoom Level */}
						{zoomLevel < 1
							? <MagnifyingGlassMinusIcon />
						: zoomLevel > 1
							? <MagnifyingGlassPlusIcon />
						: <MagnifyingGlassIcon />}

						{zoomLevel.toFixed(1)}
					</span>
				</ButtonBar>
				
			</div>

			<div onWheel={applyZoom}
				ref={grabRef}
				className={styles.panelContainer}>

				<div style={{transform: `scale(${zoomLevel})`}}> {/* Zoom container */}
					<div style={{transform: `translate(${-offset.x}px, ${-offset.y}px)`}}> {/* Pan container */}

						<TransformProvider value={{ panOffset: offset, zoomLevel: zoomLevel }}>
							{props.children}
						</TransformProvider>
					</div>
				</div>

			</div>

		</div>
	</> );
}