import { useRef, useState } from "react";
import { useDraggable } from "../hooks/useDraggable";
import Coord from "../logic/math/Coord";
import { useTransformContext } from "../hooks/useTransformContext";
import { ColorUtils, tw } from "../logic/utility";
import { NodeIO } from "./NodeStructure";
import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";

export const NodeComponent = (props?: {
	resizable?: boolean,
	collapsible?: boolean,
	color?: { shade: ColorUtils.shade, hue: ColorUtils.color } }) => {
	
	const panelTransform = useTransformContext();
	const [pos, setPos] = useState(Coord.Zero);
	const startPos = useRef(Coord.Zero);
	const [expanded, setExpanded] = useState(true);

	const [grabRef, dragProps] = useDraggable({
		callbacks: {
			startDrag: _ => { startPos.current = pos },
			whileDrag: (start, current) => { setPos(startPos.current.plus(current.minus(start).scale(1/panelTransform.zoomLevel))) }
		}
	});

	const nodeRef = useRef<HTMLDivElement>(null);

	const bringToFront = () => {
		if(!nodeRef.current) return;
		const parent = nodeRef.current?.parentElement;
		parent?.appendChild(nodeRef?.current);
	};

	const styles = {
		outer: {
			container: tw(
				"absolute",
				"overflow-hidden",
				"rounded-2xl",
				"flex flex-col justify-between",
				"z-10",
				"shadow-md hover:shadow-lg shadow-[#00000020] hover:shadow-[#00000020]",
				"transition-[shadow]",
				"min-w-[12rem]",
				props?.resizable && expanded ? "resize-x" : "min-h-[2.75rem] max-w-[12rem]"),

			titlebar: tw(
				"select-none cursor-grab",
				"w-full h-11",
				"font-semibold",
				expanded ? "rounded-t-2xl" : "rounded-2xl",
				props?.collapsible && !expanded ? "after:w-8 after:h-8 after:rotate-45 after:bg-white after:bg-opacity-20 after:absolute after:-right-4 after:-bottom-4" : "",
				"overflow-hidden",
				"flex-none flex items-center px-4",
				props?.color ? ColorUtils.colorToGradient(props?.color?.shade, props?.color?.hue) : ColorUtils.colorToGradient('dark', 'sky')),
		},

		contents: {
			wrapper: tw(
				"flex-1",
				"bg-gray-100 dark:bg-neutral-800",
				"rounded-b-2xl",
				"border-2 border-t-0 border-gray-400 dark:border-neutral-700",
				"transition-all"
			),

			ioContainer: tw(
				"w-full",
				"px-2 py-2 space-x-4",
				"flex justify-between items-stretch"),

			ioSide: tw(
				"w-full",
				"p-2",
				"border-2 rounded-md",
				"border-gray-400 dark:border-neutral-700",
				"transition-all"
			)
		}

	}

	return ( <>
		<div ref={nodeRef}
			onClick={e => { bringToFront() }}
			style={ { transform: `translate(${pos.x}px, ${pos.y}px)` } }
			className={styles.outer.container}>
			<div ref={grabRef}
				className={styles.outer.titlebar}
				onDoubleClick={e => { if(props?.collapsible) { setExpanded(ex => !ex) } }}>
				Node Title
			</div>

			{expanded &&
				<div className={styles.contents.wrapper}>
					<div className={styles.contents.ioContainer}>

						{/* Inputs */}
						<div className={styles.contents.ioSide}>
							<ArrowLeftOnRectangleIcon className="h-8 rotate-180" />
							<NodeIO modelObject={{a: "test", b: 5, c: true, d: { da: "oop", db: "scoop", dc: 2 }}}></NodeIO>
						</div>

						{/* Outputs */}
						<div className={styles.contents.ioSide + " flex justify-end"}>
							<ArrowRightOnRectangleIcon className="h-8" />
						</div>
					</div>
				</div>
			}
		</div>
	</> );
}